<template>
  <!-- 仓库详情 -->
  <div class="stockDetails page-info-content">
    <DetailsPage :detail-obj="storehouseDetailslObj" :details-item-arr="detailsItemArr" table-title="库区信息" :item-data="itemData" :list-data="storehouseDetailslObj.warehouseSubsystemInfos" />
  </div>
</template>

<script>
import DetailsPage from '@/components/DetailsPage'
export default {
  components: { DetailsPage },
  data() {
    return {
      storehouseDetailslObj: {},
      detailsItemArr: [
        { label: '所属企业', value: 'cmpName' },
        { label: '仓库名称', value: 'warehouseName' },
        { label: '联系人', value: 'linkMan' },
        { label: '联系电话', value: 'linkPhone' },
        { label: '所在地区', value: 'allCity' },
        { label: '详细地址', value: 'placeDetailAddr' },
        { label: '支持加工', value: 'isMachining', child: [{ id: '01', val: '是' }, { id: '00', val: '否' }] },
        { label: '仓库属性', value: 'warehouseProp', child: this.$store.getters.getDictionaryItem('warehouseProp') },
        { label: '仓库类型', value: 'warehouseType', child: this.$store.getters.getDictionaryItem('warehouseType') },
        { label: '仓库等级', value: 'warehouseLevel', child: this.$store.getters.getDictionaryItem('warehouseLevel') }
      ],
      itemData: [
        { label: '库区编号', prop: 'goodsYardNo', width: 180 },
        { label: '库区描述', prop: 'goodsYardRemark', width: 180 },
        { label: '货位号', prop: 'locationNo', width: 180 },
        { label: '货位描述', prop: 'locationDesc', width: 180 }
      ],
      cmpNameArr: []
    }
  },
  mounted() {
    this.getDetails()
  },
  methods: {
    getDetails() {
      this.storehouseDetailslObj = this.$store.state.resourceByTaskObj || JSON.parse(sessionStorage.resourceByTaskObj)
    }
  }
}
</script>

<style>
.stockDetails .el-descriptions {
  padding: 40px 32px 0;
}
.cargoDetail {
  padding: 10px 32px!important;
}
.stockDetails .el-descriptions-row td {
  padding-bottom: 28px !important;
}
.stockDetails .tableBox {
  margin: auto;
  text-align: center;
  width: calc(100% - 128px);
  border-collapse: collapse;
}
.stockDetails .tableBox td {
  height: 40px;
  border: 1px solid #ededed;
}
.stockDetails .el-descriptions .el-descriptions__body .link {
  color: rgb(64, 158, 255);
  text-decoration: underline;
  cursor: pointer;
}
.stockDetails .el-descriptions .el-descriptions__body {
  padding: 0 32px;
}
</style>
